export const corner = ({ theme: { colors } }) => ({
    background: colors.fuchsia,
    fontSize: '5.5rem',
    fontWeight: 'bold',
    color: colors.white,
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
});

export const extendTitle = {
    title: () => ({
        maxWidth: '54.125rem',
    }),
};

export const extendContainer = {
    container: () => ({
        position: 'relative',
        tabletLarge: {
            paddingTop: '5.625rem',
        },
    }),
};

export const link = ({ theme: { colors } }) => ({
    color: colors.blue,
    borderBottom: `0.125rem solid ${colors.blue}`,
    fontSize: '1.25rem',
    textDecoration: 'none',
    fontWeight: 'bold',
    marginBottom: '5.125rem',
    display: 'inline-block',
});

export const descLink = ({ theme: { colors } }) => ({
    color: colors.blue,
});

export const extendDesc = {
    text: () => ({
        maxWidth: '33.6875rem',
    }),
};
